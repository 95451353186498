import { field } from '../../fields';
import { FragranceId } from '../id';

export class SettingsDto {
	@field.String({ array: true, optional: true })
	fragranceSearchExcludeStrings?: string[];

	@field.String()
	imgFragrance!: string;

	@field.String()
	imgBrandBig!: string;

	@field.String()
	imgBrandSmall!: string;

	@field.String()
	imgNote!: string;

	@field.String()
	imgPerfumer!: string;

	@field.String()
	imgUser!: string;

	@field.String()
	imgIngredient!: string;

	@field.String()
	imgNotice!: string;

	@field.String()
	imgFragranceDark!: string;

	@field.String()
	imgBrandBigDark!: string;

	@field.String()
	imgBrandSmallDark!: string;

	@field.String()
	imgNoteDark!: string;

	@field.String()
	imgPerfumerDark!: string;

	@field.String()
	imgUserDark!: string;

	@field.String()
	imgIngredientDark!: string;

	@field.String()
	imgNoticeDark!: string;
}

export class StopWordsDto {
	@field.String({ array: true })
	reserved!: string[];

	@field.String({ array: true })
	swear!: string[];
}

export class RecommendsListsDto {
	@FragranceId({ array: true })
	newbies!: FragranceId[];

	@FragranceId({ array: true })
	initiated!: FragranceId[];
}
