import { Button, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { SettingsService } from '~/service/service.settings';
import { UIService } from '~/service/service.ui';
import { SettingsDto } from '~/shared/dto/admin/settings.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldImage } from '~/view/fields/field.images';
import { FieldTextArray } from '~/view/fields/field.textarray';
import { Row, Text } from '~/view/view.box';
import { EditCenter, EditContainer, EditRight } from '~/view/view.edit';

export const SettingsPage = observer(() => {
	const [ui, auth, settingsSvc] = useService(UIService, AuthService, SettingsService);
	const navBack = useNavBack();

	const form = Form.use(SettingsDto, {
		name: 'SettingsDto',
		onSubmit: dto =>
			settingsSvc.saveSettings(dto).then(() => {
				ui.notify('Settings saved successfully', 'success');
			}),
		initial: () => settingsSvc.getSettings(),
	});

	ui.usePageTitle('Settings');
	const canSave = auth.can('settings', 'UPDATE');

	return (
		<EditContainer async={settingsSvc.async}>
			<EditCenter>
				<FieldTextArray field={form.$fragranceSearchExcludeStrings} />
				<Text variant="h6">Default images for light theme:</Text>
				<Row alignItems="flex-start">
					<FieldImage field={form.$imgBrandBig} />
					<FieldImage field={form.$imgBrandSmall} />
					<FieldImage field={form.$imgFragrance} />
					<FieldImage field={form.$imgPerfumer} />
				</Row>
				<Row alignItems="flex-start">
					<FieldImage field={form.$imgIngredient} />
					<FieldImage field={form.$imgNote} />
					<FieldImage field={form.$imgUser} />
					<FieldImage field={form.$imgNotice} />
				</Row>
				<Divider />
				<Text variant="h6">Default images for dark theme:</Text>
				<Row alignItems="flex-start">
					<FieldImage field={form.$imgBrandBigDark} />
					<FieldImage field={form.$imgBrandSmallDark} />
					<FieldImage field={form.$imgFragranceDark} />
					<FieldImage field={form.$imgPerfumerDark} />
				</Row>
				<Row alignItems="flex-start">
					<FieldImage field={form.$imgIngredientDark} />
					<FieldImage field={form.$imgNoteDark} />
					<FieldImage field={form.$imgUserDark} />
					<FieldImage field={form.$imgNoticeDark} />
				</Row>
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={form.submit} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
			</EditRight>
		</EditContainer>
	);
});
