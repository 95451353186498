import styled from '@emotion/styled';
import { RemoveCircle } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { Form } from '~/shared/form/form';
import { Col, Row, Text } from '../view.box';

export const FieldImages = Form.field<string[], { images?: string[] }>(({ field, images, ...props }) => {
	const { value } = field;

	return (
		<DragDropContext
			onDragEnd={e => {
				if (!e.destination) return;
				const arr = [...(value ?? [])];
				const [removed] = arr.splice(e.source.index, 1);
				arr.splice(e.destination.index, 0, removed);
				field.set(arr);
				if (images) {
					const [removed] = images.splice(e.source.index, 1);
					images.splice(e.destination.index, 0, removed);
				}
			}}
		>
			<Col>
				<Droppable droppableId="images">
					{provided => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{value?.map((image, idx) => (
								<Draggable index={idx} draggableId={image} key={image}>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<Paper
												elevation={3}
												sx={{
													mb: 2,
													py: 1,
													px: 1,
													display: 'flex',
													flexDirection: 'column',
													alignContent: 'center',
													justifyContent: 'center',
												}}
											>
												<Img src={images?.[idx] ?? image} />
												<Row>
													<IconButton
														sx={{ position: 'relative' }}
														onClick={() => {
															field.set(field.value?.filter(i => i !== image) ?? []);
															images?.splice(idx, 1);
														}}
													>
														<RemoveCircle />
													</IconButton>
												</Row>
											</Paper>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
				<br />
				<DropField field={field} />
			</Col>
		</DragDropContext>
	);
});

const Img = styled('img')({
	maxWidth: 300,
	marginBottom: 16,
});

const DropDiv = styled('div')({
	border: '1px dashed #909090',
	padding: 20,
	textAlign: 'center',
	cursor: 'pointer',
});

const DropField = ({ field }: { field: Form.Field<any> }) => {
	const onDrop = useCallback(
		(files: File[]) => {
			console.log('onDrop', files);
			const images = files.filter(f => f.type.startsWith('image/')).map(f => URL.createObjectURL(f));
			if (images.length) field.set([...(field.value ?? []), ...images]);
		},
		[field],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<DropDiv {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<Text variant="body2">Drop the files here ...</Text>
			) : (
				<Text variant="body2">
					Drag 'n' drop some files here
					<br />
					or click to select files
				</Text>
			)}
		</DropDiv>
	);
};

export const FieldImage = Form.field<string>(({ field, ...props }) => {
	const onDrop = useCallback(
		(files: File[]) => {
			console.log('onDrop', files);
			const images = files
				.filter(f => f.type.startsWith('image/'))
				.slice(0, 1)
				.map(f => URL.createObjectURL(f));
			if (images.length) field.set(images[0]);
		},
		[field],
	);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<Paper
			elevation={3}
			sx={{
				py: 1,
				px: 1,
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'center',
				justifyContent: 'center',
			}}
		>
			<Text>{field.label}</Text>
			<SingleDropDiv style={{ backgroundImage: `url('${field.value}')` }} {...getRootProps()}>
				<input {...getInputProps()} />
			</SingleDropDiv>
		</Paper>
	);
});

const SingleDropDiv = styled('div')({
	border: '1px dashed #909090',
	textAlign: 'center',
	cursor: 'pointer',
	width: 200,
	height: 200,
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
});
