import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { BrandImportModel, FragranceImportModel, FragrancePhotoImportModel, ImportModel } from '~/model/model.import';
import { UIService } from '~/service/service.ui';
import { useModel, useService } from '~/shared/service/service.base';
import { AsyncView } from '../view.async';
import { Col, Text } from '../view.box';

export const ImportFragrancePhotosDialog = observer(() => {
	const [ui] = useService(UIService);
	const model = useModel(FragrancePhotoImportModel);
	return <ImportDialog model={model} open={ui.dialog === ImportFragrancePhotosDialog} />;
});

export const ImportFragranceDialog = observer(() => {
	const [ui] = useService(UIService);
	const model = useModel(FragranceImportModel);
	return <ImportDialog model={model} open={ui.dialog === ImportFragranceDialog} />;
});

export const ImportBrandDialog = observer(() => {
	const [ui] = useService(UIService);
	const model = useModel(BrandImportModel);
	return <ImportDialog model={model} open={ui.dialog === ImportBrandDialog} />;
});

const DropDiv = styled('div')({
	border: '1px dashed #909090',
	padding: 20,
	textAlign: 'center',
	cursor: 'pointer',
});

const ImportDialog = observer<{ model: ImportModel<any>; open: boolean }>(({ model, open }) => {
	const [ui] = useService(UIService);
	const onDrop = useCallback(
		([file]: File[]) => {
			model.loadCSV(file);
		},
		[model],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: false,
		accept: { 'application/csv': ['.csv'] },
	});

	useEffect(() => {
		if (open) {
			model.reset();
		}
	}, [model, open]);

	return (
		<Dialog open={open} onClose={ui.hideDialog} PaperProps={{ sx: { width: 400 } }}>
			<DialogTitle>Import from CSV</DialogTitle>
			<DialogContent>
				<AsyncView async={model.async}>
					<DropDiv {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<Text variant="body2">Drop the CSV file here ...</Text>
						) : (
							<Text variant="body2">
								Drag 'n' drop CSV file here
								<br />
								or click to select file
							</Text>
						)}
					</DropDiv>

					<Col>
						<Grid container gridColumn={2} columnGap={2}>
							<Grid>
								<Text variant="body2">Rows:</Text>
							</Grid>
							<Grid>
								<Text variant="body2">{model.data?.length ?? 0}</Text>
							</Grid>
						</Grid>
						<Grid container gridColumn={2} columnGap={2}>
							<Grid>
								<Text variant="body2">Errors:</Text>
							</Grid>
							<Grid>
								<Text variant="body2">{model.errors}</Text>
							</Grid>
						</Grid>
						<Grid container gridColumn={2} columnGap={2}>
							<Grid>
								<Text variant="body2">Last error:</Text>
							</Grid>
							<Grid>
								<Text variant="body2">{model.error}</Text>
							</Grid>
						</Grid>
						{model.result && (
							<>
								<Grid container gridColumn={2} columnGap={2}>
									<Grid>
										<Text variant="body2">Imported:</Text>
									</Grid>
									<Grid>
										<Text variant="body2">{model.result.imported}</Text>
									</Grid>
								</Grid>
								<Grid container gridColumn={2} columnGap={2}>
									<Grid>
										<Text variant="body2">Inserted:</Text>
									</Grid>
									<Grid>
										<Text variant="body2">{model.result.inserted}</Text>
									</Grid>
								</Grid>
								<Grid container gridColumn={2} columnGap={2}>
									<Grid>
										<Text variant="body2">Updated:</Text>
									</Grid>
									<Grid>
										<Text variant="body2">{model.result.updated}</Text>
									</Grid>
								</Grid>
								<Grid container gridColumn={2} columnGap={2}>
									<Grid>
										<Text variant="body2">Skipped:</Text>
									</Grid>
									<Grid>
										<Text variant="body2">{model.result.skipped}</Text>
									</Grid>
								</Grid>
							</>
						)}
					</Col>
				</AsyncView>
			</DialogContent>
			<DialogActions>
				<Button disabled={model.async.loading} onClick={ui.hideDialog}>
					Close
				</Button>
				<Button disabled={!model.canImport} onClick={model.saveImported}>
					Import
				</Button>
			</DialogActions>
		</Dialog>
	);
});
